import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import './login.scss';
import logo from '../../assets/msw-logo-white.png';
import { browserName, deviceType, getUA, osName, osVersion } from "react-device-detect";

const Login: React.FC = () => {
    const navigate = useNavigate();
    const useragent = getUA;

    const userRef = useRef<HTMLInputElement | null>(null);
    const errRef = useRef<HTMLDivElement | null>(null);

    const [uname, setUname] = useState<string>('');
    const [pword, setPword] = useState<string>('');
    const [ipaddress, setIPAddress] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [formSubmit, setFormSubmit] = useState<boolean>(false);
    // const [otpVerification, setOTPVerification] = useState<boolean>(false);
    // const [otp, setOTP] = useState<string>('');

    useEffect(() => {
        userRef.current?.focus();

        // Get IP address
        // axios.get('https://api.ipify.org?format=json').then((response) => {
        //     setIPAddress(response.data.ip);
        // });
    }, []);

    useEffect(() => {
        setErrorMessage('');
    }, [uname, pword]);

    // useEffect(() => {
    //     if (otpVerification) {
    //         userRef.current?.focus();
    //     }
    // }, [otpVerification]);

    // const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const inputValue = e.target.value;
    //     if (inputValue.length <= 6 && /^[0-9]*$/.test(inputValue)) { // Ensure it contains only digits
    //         setOTP(inputValue);
    //     }
    // };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmit(true);

        try {
            const response = await axios.post('auth', JSON.stringify({
                uname,
                pword,
                // otp,
                // useragent,
                // browser: browserName,
                // device: deviceType,
                // os: `${osName} ${osVersion}`,
                // ipaddress
            }), {
                headers: { 'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN }
            });

            if (response) {
                // if (response.data.status === 'otp') {
                //     setOTPVerification(true);
                //     setFormSubmit(false);
                // } else {
                    const { token: accesstoken, name, theme } = response.data;

                    const details = await axios.post('getuserdetails', JSON.stringify({}), {
                        headers: { 'X-Aim-Token': accesstoken }
                    });

                    const { email = '', employee_id, image, department, department_slug, outlet_name, outlet_code } = details.data;

                    sessionStorage.setItem('uname', uname);
                    sessionStorage.setItem('name', name);
                    sessionStorage.setItem('email', email);
                    sessionStorage.setItem('emp_id', employee_id);
                    sessionStorage.setItem('img', image);
                    sessionStorage.setItem('department', department);
                    sessionStorage.setItem('department_code', department_slug);
                    sessionStorage.setItem('outlet', outlet_name);
                    sessionStorage.setItem('outlet_code', outlet_code);
                    sessionStorage.setItem('theme', theme);
                    sessionStorage.setItem('accessToken', accesstoken);

                    navigate('/cRJxA/dashboard');
                    setUname('');
                    setPword('');
                    // setOTP(''); // Reset OTP after successful login
                    setFormSubmit(false);
                // }
            }
        } catch (error: any) {
            if (!error?.response) {
                setErrorMessage('No server response!');
            } else {
                setErrorMessage(error.response?.data.msg);
            }
            errRef.current?.focus();
            setFormSubmit(false);
        }
    };

    return (
        <div className="login">
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div ref={errRef} className={`error-message ${errorMessage ? ' show' : ' hide'}`} aria-live="assertive">
                        <p>
                            <i className="fa fa-exclamation-triangle"></i> 
                            <span>{errorMessage}</span>
                        </p>
                    </div>

                    <div className="header">
                        <div className="logo-container">
                            <div className="logo">
                                <img src={logo} alt="MSW" />
                            </div>
                        </div>

                        {/* Waves container */}
                        <div className="waves">
                            <svg className='waves' xmlns='//www.w3.org/2000/svg' xmlnsXlink='//www.w3.org/1999/xlink' viewBox='0 24 150 28' preserveAspectRatio='none' shapeRendering='auto'>
                                <defs>
                                    <path id='gentle-wave' d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z' />
                                </defs>
                                <g className='parallax'>
                                    <use xlinkHref='#gentle-wave' x='48' y='0' fill='rgba(255,255,255,0.7)' />
                                    <use xlinkHref='#gentle-wave' x='48' y='3' fill='rgba(255,255,255,0.5)' />
                                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                    </div>

                    {/* {otpVerification ? (
                        <div className="body otp">
                            <p>Enter your One-Time Password (OTP)</p>
                            <input
                                type="number"
                                id="otp"
                                placeholder="Enter Code"
                                autoComplete="off"
                                ref={userRef}
                                onChange={handleOTPChange}
                                value={otp}
                                minLength={6}
                                maxLength={6}
                                required
                                disabled={formSubmit}
                            />
                            <button disabled={formSubmit}>
                                SUBMIT 
                                {formSubmit && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '5px' }}></i>}
                            </button>
                        </div>
                    ) : ( */}
                        <div className="body">
                            <input
                                type="text"
                                id="uname"
                                placeholder="Username"
                                autoComplete="off"
                                ref={userRef}
                                onChange={(e) => { setUname(e.target.value) }}
                                value={uname}
                                required
                                disabled={formSubmit}
                            />
                            <input
                                type="password"
                                id="pword"
                                placeholder="Password"
                                onChange={(e) => { setPword(e.target.value) }}
                                value={pword}
                                required
                                disabled={formSubmit}
                            />
                            <button disabled={formSubmit}>
                                LOGIN 
                                {formSubmit && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '5px' }}></i>}
                            </button>
                            <p>
                                <Link to="forgot-password">Forgot Password?</Link>
                            </p>
                        </div>
                    {/* )} */}
                </form>
            </div>
        </div>
    );
};

export default Login;
